import * as React from 'react'
import { ApolloError } from '@apollo/client'
import ErrorView from '~/components/ErrorView'
import NotFound from '~/components/NotFound'
import { DEBUG } from '~/config'

type NetworkError = ApolloError['networkError'] & {
  statusCode?: number,
}

export type Props = {
  error: ApolloError,
}

export const GQLError = ({ error }: Props) => {
  const networkError = error.networkError as NetworkError
  const statusCode = networkError && (networkError.statusCode || 500)
  if (statusCode === 404) {
    return <NotFound />
  }

  const title = 'Oh no! Something went wrong!'
  const message = error.message.split(':')[0] || 'Unknown Error'
  const debugMessage = DEBUG ? JSON.stringify(error, null, 2) : ''
  return (
    <ErrorView
      title={title}
      debugMessage={debugMessage}
      statusCode={statusCode}
    >
      <p>{message}</p>
    </ErrorView>
  )
}

export default GQLError
