import scroll from 'scroll'
import { window, document, getScrollingElement } from '~/utils/dom'

export const easeInOut = (n: number) => (
  n < 0.5
    ? ((n * 2) ** 2) / 2
    : 1 - (((1 - n) * 2) ** 2) / 2
)

export const DEFAULT_EASE = easeInOut

export type ScrollOptions = {
  duration?: number,
  offset?: number,
  ease?: (time: number) => number,
  onEnd?: () => void,
  cancelable?: boolean,
}

/**
 * Scroll page to the given position
 */
export function scrollTo (y: number, opt: ScrollOptions = {}) {
  // handle defaults
  const duration = opt?.duration
  const offset = opt?.offset ?? 0
  const ease = opt?.ease ?? DEFAULT_EASE
  const onEnd = opt?.onEnd
  const cancelable = opt.cancelable ?? true

  // find scrollable element
  const scrollEl = getScrollingElement()

  // do the scroll
  const cancel = scroll.top(scrollEl, y + offset, {
    duration,
    ease,
  }, () => {
    if (onEnd) onEnd()

    // clean up event listeners
    if (cancelable) {
      window.removeEventListener('wheel', cancel)
      window.removeEventListener('touchstart', cancel)
      window.removeEventListener('mousedown', cancel)
    }
  })

  // cancel scroll on user interaction
  if (cancelable) {
    window.addEventListener('wheel', cancel)
    window.addEventListener('touchstart', cancel)
    window.addEventListener('mousedown', cancel)
  }
}

/**
 * Scroll to the given element
 */
export function scrollToElement (arg: string | HTMLElement, opt: ScrollOptions = {}) {
  // find DOM node if given as a string
  const el = typeof arg === 'string'
    ? document.querySelector(arg) as HTMLElement
    : arg
  if (!el) return

  // find scrollable element
  const scrollEl = getScrollingElement()

  // calculate scroll top position
  const offset = opt?.offset ?? 0
  const bbox = el.getBoundingClientRect()
  const top = bbox.top + scrollEl.scrollTop + offset

  scrollTo(top, opt)
}
