import * as React from 'react'
import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { Helmet } from 'react-helmet-async'
import { RouteComponentProps } from 'react-router'
import GQLError from '~/components/GQLError'
import NotFound from '~/components/NotFound'
import Project from '~/components/Project'
import { Project as ProjectType } from '~/types'

const PROJECT_QUERY = gql`
  query ProjectQuery ($slug: String!) {
    project (slug: $slug) {
      id
      slug
      title
      description
      image {
        id
        props {
          id
          sizes
        }
      }
      images {
        id
        title
        props {
          id
          url
          contentType
          altText
          sizes
        }
      }
    }
  }
`

type QueryData = {
  project: ProjectType,
}

type QueryVars = {
  slug: string,
}

export type Props = RouteComponentProps<QueryVars>

export const ProjectPage = ({ match, location, history }: Props) => {
  const handleClose = () => {
    history.push('/')
  }

  const { loading, error, data } = useQuery<QueryData, QueryVars>(PROJECT_QUERY, {
    variables: match.params,
  })

  if (loading) return null
  if (error) return <GQLError error={error} />
  if (!data || !data.project) return <NotFound location={location} />

  const { project } = data
  const title = project.title.replace(/(\n|\r|\r\n)/gm, ' ')
  const description = project.description.replace(/(\n|\r|\r\n)/gm, ' ')
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta property="og:type" content="article" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        {!!project.image && <meta property="og:image" content={project.image.props.sizes['1080p'].url} />}
      </Helmet>

      <Project
        project={project}
        onClose={handleClose}
      />
    </>
  )
}

export default ProjectPage
