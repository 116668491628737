import * as React from 'react'

import './CloseButton.scss'

export type Props = {
  className?: string,
} & React.ButtonHTMLAttributes<HTMLButtonElement>

export const CloseButton = ({ className = '', ...props }: Props) => (
  <button
    className={`CloseButton ${className || ''}`}
    {...props}
  />
)

export default CloseButton
