import window from 'window-or-global'

export { window }

export const document = window.document ?? null

/**
 * Returns the top-level scrollable element
 */
export function getScrollingElement () {
  return (document.scrollingElement || document.documentElement) as HTMLElement
}
