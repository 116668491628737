import * as React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { ApolloClient } from '@apollo/client'
import { HelmetProvider } from 'react-helmet-async'
import App from '../App'


export type Props = {
  apolloClient: ApolloClient<any>,
}

export const ClientApp = ({ apolloClient }: Props) => (
  <BrowserRouter>
    <HelmetProvider>
      <App apolloClient={apolloClient} />
    </HelmetProvider>
  </BrowserRouter>
)

export default ClientApp
