/* global window */
import * as React from 'react'
import { useState, useCallback, useEffect } from 'react'
import { default as cx } from 'classnames'

export type Props = React.ImgHTMLAttributes<HTMLImageElement>

export const BLANK_SRC = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='

/**
 * Image component
 */
export const Image = React.memo(({
  src,
  className,
  ...props
}: Props) => {
  // Handle loading state
  const [isLoaded, setIsLoaded] = useState(true)

  const handleLoad = useCallback(() => {
    setIsLoaded(true)
  }, [])

  useEffect(() => {
    setIsLoaded(false)
    const image = new (window as any).Image()
    image.onload = handleLoad
    image.src = src
  }, [src])

  return (
    <img
      src={(src || BLANK_SRC)}
      alt={props?.alt ?? ''}
      className={cx('Image', className, {
        'Image--isLoadeing': !isLoaded,
      })}
      {...props}
      style={{
        ...props?.style,
        opacity: isLoaded ? props?.style?.opacity : 0,
      }}
    />
  )
})

export default Image
