import * as React from 'react'
import { useRef, createRef } from 'react'
import window from 'window-or-global'
import { Route } from 'react-router-dom'
import { scrollToElement } from '~/utils/scrollTo'
import { useEventListener } from '~/hooks/useEventListener'
import { useWheelIndicator, WheelIndicatorEvent } from '~/hooks/useWheelIndicator'
import Slide from '~/components/ui/Slide'
import ToggleLink from '~/components/ui/ToggleLink'
import Layout from './MainLayout'
import ProjectList from '~/components/ProjectList'
import ProjectPage from '~/routes/components/ProjectPage'
import Image from '~/components/ui/Image'
import type { ListProject, SplashPage } from '~/types'

import './Home.scss'

export type Props = {
  projects: ListProject[],
  homeSplash: SplashPage,
  contactSplash: SplashPage,
  onKeyDown: (e: KeyboardEvent) => void,
  onWheel: (e: WheelIndicatorEvent) => void,
  onHomeClick: (e: React.MouseEvent) => void,
  onResize: () => void,
}

export const Home = ({
  projects,
  homeSplash,
  contactSplash,
  onKeyDown,
  onWheel,
  onHomeClick,
  onResize,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null)

  useEventListener('resize', onResize)
  useEventListener('keydown', onKeyDown)
  useWheelIndicator(ref, onWheel)

  return (
    <Layout>
      <Route path="/projects/:slug" exact component={ProjectPage} />

      <div className="Home" ref={ref}>
        <Slide
          className={`Home__landing ${homeSplash.fgImage ? 'hasFgImage' : ''}`}
          id="home"
          image={homeSplash.bgImage?.props.sizes['1080p'].url}
        >
          <ToggleLink as="a" bgColor="red" href="#contact" onClick={onHomeClick}>
            {homeSplash.fgImage ? (
              <Image
                src={homeSplash.fgImage.props.url}
                alt={homeSplash.fgImage.props.altText || 'Riko Enomoto'}
              />
            ) : (
              <h1>Riko Enomoto</h1>
            )}
          </ToggleLink>
        </Slide>

        {projects && (
          <ProjectList projects={projects} />
        )}

        <Slide
          className="Home__contact"
          id="contact"
          image={contactSplash.bgImage?.props.sizes['1080p'].url}
        >
          <ToggleLink as="a" href="hello@rikoenomoto.com">
            <h1>Get in Touch</h1>
            <p>hello@rikoenomoto.com</p>
          </ToggleLink>
        </Slide>
      </div>
    </Layout>
  )
}

type ControllerProps = Pick<Props, 'projects' | 'homeSplash' | 'contactSplash' >

type State = {
  sections: string[],
  activeIndex: number,
  hasAutoScrolled: boolean,
}

const document = window.document
const IntersectionObserver = window.IntersectionObserver
const SUPPORTS_OBSERVER = !!IntersectionObserver

export class HomeController extends React.PureComponent<ControllerProps, State> {
  observer?: IntersectionObserver
  ref = createRef<HTMLDivElement>()

  state = {
    sections: [],
    activeIndex: 0,
    hasAutoScrolled: false,
  } as State

  static getDerivedStateFromProps (props: ControllerProps, state: State) {
    return {
      sections: [
        'home',
        ...(props.projects.map(p => p.slug)),
        'contact',
      ],
    }
  }

  componentDidMount () {
    this.observeScroll()
  }

  componentWillUnmount () {
    this.observer?.disconnect()
  }

  scrollToItem = (id: string, duration = 350) => {
    scrollToElement(`#${id}`, { duration, cancelable: false })
    this.setState({ hasAutoScrolled: true })
  }

  scrollToActive = (duration?: number) => {
    this.scrollToItem(this.state.sections[this.state.activeIndex], duration)
  }

  goToNext = () => {
    this.setState(state => ({
      activeIndex: Math.min(state.activeIndex + 1, state.sections.length - 1),
    }), this.scrollToActive)
  }

  goToPrev = () => {
    this.setState(state => ({
      activeIndex: Math.max(state.activeIndex - 1, 0),
    }), this.scrollToActive)
  }

  goToLast = () => {
    this.setState(state => ({
      activeIndex: state.sections.length - 1,
    }), this.scrollToActive)
  }

  observeScroll = () => {
    // This requires IntersectionObserver api
    if (!SUPPORTS_OBSERVER) return

    const { sections } = this.state

    // Use IntersectionObserver api to find active section based on
    // the amount the page is scrolled.
    this.observer = new IntersectionObserver((entries) => {
      const visibleEntries = entries
        .filter(e => e.isIntersecting)
        .sort((a, b) => (
          b.intersectionRatio - a.intersectionRatio
        ))

      const activeEntry = visibleEntries[0]
      if (activeEntry) {
        const index = sections.indexOf(activeEntry.target.id)
        this.setState({ activeIndex: index })
      }
    }, {
      threshold: 0.6,
    })

    // Set up observers for each section
    sections.forEach(id => {
      const el = document?.getElementById(id)
      if (el && this.observer) this.observer.observe(el)
    })
  }

  handleKeyDown = (e: KeyboardEvent) => {
    switch (e.keyCode) {
      case 40:
      case 34:
        e.preventDefault()
        this.goToNext()
        break
      case 38:
      case 33:
        e.preventDefault()
        this.goToPrev()
        break
      default:
        break
    }
  }

  handleWheel = (e: WheelIndicatorEvent) => {
    e.preventDefault()
    if (e.direction === 'down') this.goToNext()
    else this.goToPrev()
  }

  handleHomeClick = (e: React.MouseEvent) => {
    e.preventDefault()
    this.goToLast()
  }

  handleResize = () => {
    if (this.state.hasAutoScrolled) this.scrollToActive(0.01)
  }

  render () {
    const { projects, homeSplash, contactSplash } = this.props
    return (
      <Home
        projects={projects}
        homeSplash={homeSplash}
        contactSplash={contactSplash}
        onKeyDown={this.handleKeyDown}
        onWheel={this.handleWheel}
        onHomeClick={this.handleHomeClick}
        onResize={this.handleResize}
      />
    )
  }
}

export default HomeController
