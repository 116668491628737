/* global module */
import * as React from 'react'
import { hydrate } from 'react-dom'
import configureApolloClient from '~/apolloClient'
import ClientApp from './ClientApp'


// apollo client
const apolloClient = configureApolloClient({
  initialState: window.__APOLLO_STATE__,
})

hydrate(
  <ClientApp apolloClient={apolloClient} />,
  document.getElementById('root'),
)

if (module.hot) {
  module.hot.accept()
}
