/* global document */
import * as React from 'react'

export type Props = {
  className: string,
  children?: React.ReactNode,
}

export class BodyClass extends React.PureComponent<Props> {
  static addClass (cls: string) {
    if (cls && document.body) {
      document.body.classList.add(cls)
    }
  }

  static removeClass (cls: string) {
    if (cls && document.body) {
      document.body.classList.remove(cls)
    }
  }

  componentDidMount () {
    BodyClass.addClass(this.props.className)
  }

  componentDidUpdate (prevProps: Props) {
    if (prevProps.className !== this.props.className) {
      BodyClass.removeClass(prevProps.className)
      BodyClass.addClass(this.props.className)
    }
  }

  componentWillUnmount () {
    BodyClass.removeClass(this.props.className)
  }

  render () {
    return this.props.children || null
  }
}

export default BodyClass
