import * as React from 'react'

import './ShadowBox.scss'

export type Props = {
  children: React.ReactNode,
  className?: string,
  bgColor?: 'white' | 'red' | 'yellow',
  as?: React.ComponentType<any> | keyof JSX.IntrinsicElements,
} & React.HTMLAttributes<any>

export const ShadowBox = ({
  children,
  className = '',
  bgColor = 'white',
  as: T = 'div',
  ...props
}: Props) => (
  <T {...props} className={`ShadowBox ShadowBox--color-${bgColor} ${className}`}>
    {children}
  </T>
)

export default ShadowBox
