/* eslint react/no-danger: 0 */
import * as React from 'react'
import window from 'window-or-global'
import { withRouter, RouteComponentProps } from 'react-router'

export type Props = RouteComponentProps & {
  children: string,
}

export class Markup extends React.PureComponent<Props> {
  ref = React.createRef() as React.RefObject<HTMLSpanElement>

  static defaultProps = {
    children: '',
  }

  componentDidMount () {
    this.postRender()
  }

  componentDidUpdate (prevProps: Props) {
    if (prevProps.children !== this.props.children) {
      this.postRender()
    }
  }

  postRender = () => {
    this.attachLinkListeners()
  }

  attachLinkListeners = () => {
    const el = this.ref.current as HTMLElement
    if (el) {
      const anchors = el.querySelectorAll('a')
      const { location } = window
      Array.from(anchors)
        .filter(a => (
          a.pathname && a.host === location.host
        ))
        .forEach(a => {
          a.removeEventListener('click', this.handleAnchorClick)
          a.addEventListener('click', this.handleAnchorClick)
        })
    }
  }

  // Anchor click handler
  handleAnchorClick = (e: MouseEvent) => {
    const { history } = this.props
    e.preventDefault()
    const anchor = e.currentTarget as HTMLAnchorElement
    history.push(anchor.pathname)
  }

  render () {
    return (
      <span
        className="Markup"
        ref={this.ref}
        dangerouslySetInnerHTML={{ __html: this.props.children }}
      />
    )
  }
}

export default withRouter(Markup)
