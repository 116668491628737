import * as React from 'react'
import { useState } from 'react'
import { default as cx } from 'classnames'
import window from 'window-or-global'
import { NavLink as Link, useHistory } from 'react-router-dom'
import ShadowBox from './ShadowBox'

import './ToggleLink.scss'

export type Props = {
  children: React.ReactNode,
  bgColor?: 'white' | 'red' | 'yellow',
  duration?: number,
  as?: React.ComponentType<any> | keyof JSX.IntrinsicElements,
} & Partial<React.ComponentProps<typeof Link>>

export const ToggleLink = ({ children, duration = 120, ...props }: Props) => {
  const history = useHistory()

  // Animated pressed state before handling click/link to
  const [pressed, setPressed] = useState(false)

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    setPressed(true)

    setTimeout(() => {
      setPressed(false)

      setTimeout(() => {
        if (props.onClick) props.onClick(e)
        if (props.to) {
          history.push(typeof props.to === 'string' ? props.to : (props.to as any).pathname)
        }
        else if (props.href) {
          (window as any).location = props.href
        }
      }, duration + 40)
    }, duration + 30)
  }

  return (
    <ShadowBox
      as={Link}
      {...props}
      onClick={handleClick}
      className={cx('ToggleLink', props.className, {
        pressed,
      })}
      style={{
        transitionDuration: `${duration}ms`,
        ...props.style,
      }}
    >
      {children}
    </ShadowBox>
  )
}

export default ToggleLink
