import * as React from 'react'
import { ApolloClient, ApolloProvider } from '@apollo/client'
import { Helmet } from 'react-helmet-async'
import routes from './routes'

import './styles/index.scss'

export type Props = {
  apolloClient: ApolloClient<any>,
}

const siteTitle = 'Riko Enomoto'

export const App = ({ apolloClient }: Props) => (
  <ApolloProvider client={apolloClient}>
    <Helmet titleTemplate="%s | Riko Enomoto" defaultTitle={siteTitle}>
      <html lang="en" />
      <link rel="icon" href="data:;base64,iVBORw0KGgo=" />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={siteTitle} />
      <meta property="og:title" content={siteTitle} />
    </Helmet>
    <div className="App">
      {routes}
    </div>
  </ApolloProvider>
)

export default App
