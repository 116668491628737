import * as React from 'react'

import './MainLayout.scss'

export type Props = {
  children: React.ReactNode,
}

export const Layout = (props: Props) => (
  <div className="MainLayout">
    {props.children}
  </div>
)

export default Layout
