/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import * as React from 'react'
import { useRef, useEffect, useMemo } from 'react'
import window from 'window-or-global'
import { default as cx } from 'classnames'
import { useEventListener } from '~/hooks/useEventListener'
import BodyClass from '~/components/ui/BodyClass'
import ShadowBox from '~/components/ui/ShadowBox'
import CloseButton from '~/components/ui/CloseButton'

import './Modal.scss'

export type Props = {
  onClose: () => void,
  children?: React.ReactNode,
} & React.HTMLAttributes<HTMLDivElement>

const document = window.document

// Track window load (for animations)
let enableAnimation = false
if ('addEventListener' in window) {
  window.addEventListener('load', () => {
    setTimeout(() => { enableAnimation = true }, 1000)
  })
}

export const Modal = ({ onClose, children, ...props }: Props) => {
  // dom node ref
  const domRef = useRef<HTMLDivElement>(null)

  // Close the modal
  const previouslyFocused = useMemo(() => document?.activeElement as HTMLElement, [])
  const closeModal = () => {
    onClose()
    previouslyFocused?.focus()
  }

  // Esc key closes
  useEventListener('keydown', React.useCallback((e: KeyboardEvent) => {
    if (e.keyCode === 27) {
      e.preventDefault()
      e.stopPropagation()
      closeModal()
    }
  }, []))

  // Focus modal on mount
  const focusModal = () => {
    domRef.current?.focus()
  }
  useEffect(focusModal, [])

  return (
    <>
      <BodyClass className="Modal--isOpen" />

      <div className="Modal__backdrop" role="presentation" onClick={closeModal} />

      <div
        ref={domRef}
        className={cx('Modal', { 'Modal--withAnimation': enableAnimation })}
        tabIndex={-1}
        role="dialog"
        aria-modal="true"
        {...props}
        onTransitionEnd={focusModal}
        onClick={closeModal}
      >
        <div className="Modal__container">
          <ShadowBox className="Modal__box" onClick={(e) => { e.stopPropagation() }}>
            <div className="Modal__content">
              <CloseButton className="Modal__close" onClick={closeModal} aria-label="close dialog" />
              {children || null}
            </div>
          </ShadowBox>
        </div>
      </div>
    </>
  )
}

export default Modal
