import * as React from 'react'
import Image from '~/components/ui/Image'

import './Slide.scss'

export type Props = {
  children: React.ReactNode,
  className?: string,
  image?: string,
} & React.HTMLAttributes<HTMLDivElement>

export const Slide = ({ children, image, className = '', ...props }: Props) => (
  <section
    className={`Slide ${className}`}
    {...props}
  >
    {!!image && <Image src={image} className="Slide__bg-image" loading="lazy" />}
    <div className="Slide__contents">{children}</div>
  </section>
)

export default Slide
