/* eslint consistent-return: off */
import { useEffect, RefObject } from 'react'
import WheelIndicator from 'wheel-indicator'

export type WheelIndicatorEvent = WheelEvent & {
  direction: 'up' | 'down',
}

export const useWheelIndicator = (
  domRef: RefObject<HTMLElement>,
  onWheel: (e: WheelIndicatorEvent) => void,
  preventMouse = true,
) => {
  useEffect(() => {
    if (!domRef.current) return

    const indicator = new WheelIndicator({
      elem: domRef.current,
      callback: onWheel,
      preventMouse,
    })

    return () => {
      indicator.destroy()
    }
  }, [
    domRef,
    onWheel,
    preventMouse,
  ])
}

export default useWheelIndicator
