import * as React from 'react'
import { default as cx } from 'classnames'
import Markup from '~/components/ui/Markup'
import Modal from '~/components/ui/Modal'
import Image from '~/components/ui/Image'
import { Project as ProjectType } from '~/types'

import './Project.scss'

export type Props = {
  project: ProjectType,
  onClose: () => void,
}

export const Project = ({ project, ...props }: Props) => (
  <div className="Project">
    <Modal onClose={props.onClose} aria-label={project.title}>
      <div className="Project__content">
        <div className="Project__description">
          <Markup>{project.description}</Markup>
        </div>

        <ol
          className={cx('Project__images', {
            'Project__images--with-titles': project.images.filter(i => !!i.title).length,
          })}
        >
          {project.images.map(image => (
            <li className="Project__image" key={image.id}>
              {image.title && <h4 className="Project__image__title">{image.title}</h4>}
              <Image
                src={image.props.sizes['720p'].url}
                alt={image.props.altText ?? image.title}
                className="Project__image__img"
              />
            </li>
          ))}
        </ol>
      </div>
    </Modal>
  </div>
)

export default Project
