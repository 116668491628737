import { ApolloClient, InMemoryCache, ApolloLink, HttpLink } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import fetch from 'cross-fetch'
import { GRAPHQL_ENDPOINT } from '~/config'

function configureApolloClient ({
  uri = GRAPHQL_ENDPOINT,
  ssrMode = false,
  initialState = {},
} = {}) {
  const client = new ApolloClient({
    link: configureLink(uri),
    cache: configureCache(initialState),
    ssrMode,
  })
  return client
}

function configureCache (initialState = {}) {
  return new InMemoryCache().restore(initialState)
}

function configureLink (uri: string) {
  return ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (networkError) {
        console.error(`[Network error]: ${networkError}`)
      }
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
        })
      }
    }),
    new HttpLink({
      uri,
      fetch,
      credentials: 'same-origin',
    }),
  ])
}

export default configureApolloClient
