import * as React from 'react'
import Slide from '~/components/ui/Slide'
import ToggleLink from '~/components/ui/ToggleLink'
import { ListProject } from '~/types'

import './ProjectList.scss'

export type Props = {
  projects: ListProject[],
}

export const ProjectList = ({ projects }: Props) => (
  <>
    {projects.map(project => (
      <Slide key={project.id} image={project.image?.props.sizes['1080p'].url} id={project.slug}>
        <ToggleLink to={`/projects/${project.slug}`} className="ProjectList__link">
          <h2 className="ProjectList__title">{project.title}</h2>
        </ToggleLink>
      </Slide>
    ))}
  </>
)

export default ProjectList
