import * as React from 'react'
import { Link } from 'react-router-dom'
import Status from './Status'

import './ErrorView.scss'

export type Props = {
  title: string,
  children?: React.ReactNode,
  className?: string,
  debugMessage?: string,
  statusCode?: number,
}

export const ErrorView = ({ title, children, className = '', debugMessage = '', statusCode }: Props) => (
  <div className={`ErrorView ${className}`}>
    {statusCode ? <Status code={404} /> : null}
    <div className="ErrorView__contents">
      <h1>{statusCode || ''} {title}</h1>
      {children || null}
      <p><Link to="/">Go Home</Link></p>

      {debugMessage ? (
        <code className="ErrorView__debug">{debugMessage}</code>
      ) : null}
    </div>
  </div>
)

ErrorView.defaultProps = {
  children: 'oh no! Something went wrong!',
}

export default ErrorView
