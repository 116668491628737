import * as React from 'react'
import { Route, Switch } from 'react-router-dom'
import NotFound from '~/components/NotFound'
import HomePage from './components/HomePage'

export default (
  <Switch>
    <Route path="/" exact component={HomePage} />
    <Route path="/projects/:slug" exact component={HomePage} />
    <Route component={NotFound} />
  </Switch>
)
