import * as React from 'react'
import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import GQLError from '~/components/GQLError'
import Home from '~/components/Home'
import type { ListProject, SplashPage } from '~/types'

const HOME_QUERY = gql`
  query HomeQuery {
    projects {
      id
      slug
      title
      image {
        id
        props {
          id
          url
          contentType
          altText
          sizes
        }
      }
    }

    homeSplash: splashPage(slug: "home") {
      id
      slug
      bgImage {
        id
        props {
          id
          url
          contentType
          altText
          sizes
        }
      }
      fgImage {
        id
        props {
          id
          url
          contentType
          altText
          sizes
        }
      }
    }

    contactSplash: splashPage(slug: "contact") {
      id
      slug
      bgImage {
        id
        props {
          id
          url
          contentType
          altText
          sizes
        }
      }
    }

  }
`

type QueryData = {
  projects: ListProject[],
  homeSplash: SplashPage,
  contactSplash: SplashPage,
}

export const HomePage = () => {
  const { loading, error, data } = useQuery<QueryData>(HOME_QUERY)

  if (error) return <GQLError error={error} />
  if (loading || !data) return null // return <Loading />

  const { projects, homeSplash, contactSplash } = data
  return (
    <Home
      projects={projects}
      homeSplash={homeSplash}
      contactSplash={contactSplash}
    />
  )
}

export default HomePage
